import * as PIXI from 'pixi.js-legacy';
import * as React from 'react';
import { ICompositionLayer } from '../../../constants/snippets';
import { ColorMatrixFilter } from '@pixi/filter-color-matrix';
import { AdjustmentFilter } from '@pixi/filter-adjustment';
import { ColorOverlayFilter } from '@pixi/filter-color-overlay';
import { getInterpolatedConfigValue, getLayerPosition } from '../../../util/timeline';
import { layerSourceError } from './layerSourceError';
import {
    COMPOSITION_LAYER_COLORS,
    COMPOSITION_LAYER_TYPES,
    SOURCE_TYPES
} from '../../../constants/story';
import { preview as copy } from '../../../constants/copy';
import { LAYER_DRAW_COMPLETE, LAYER_SOURCE_STATUSES } from '../../../constants/editor';
import { FRAME_SEEK_OFFSET } from '../../../constants/timeline';
import { checkAvailableAssets } from '../../filterListTable';

interface ILayerSourceProps {
    sourceKey?: string;
    storyId?: string;
    inView: boolean;
    playing: boolean;
    frameRate: number;
    parent: any;
    zIndex: number;
    variables: any;
    variableMap?: any;
    color: string;
    assets?: any;
    compositions?: any;
    layerData: ICompositionLayer;
    relativeFrame: number;
    relativeTime: number;
    compWidth: number;
    compHeight: number;
    mounted: boolean;
    renderMode: boolean;
    onClick(id: string, multiSelect?: boolean): void;
    interimPosition: any;
    source?: any;
    onReady?(): void;
    clearLayerSource?(): void;
    setLayerSource?(source): any;
    muted?: boolean;
    volume?: number;
    isMatte?: boolean;
    pixiApp?: PIXI.Application;
}

export interface ILayerSourceState {
    status: string;
    playing: boolean;
    sourceWidth: number;
    sourceHeight: number;
    capturingScreenshot: boolean;
}

export default class LayerSource extends React.PureComponent<ILayerSourceProps, ILayerSourceState> {
    public layerSourceTimeout: number;

    public contentSprite: PIXI.Sprite;

    public mediaSprite: PIXI.Sprite;

    public maskGraphic: PIXI.Graphics;

    public matteSprite: PIXI.Sprite;

    public matteTexture: PIXI.Texture;

    public quadShader: PIXI.Shader;

    public quadMesh: any;

    public quadGraphics: any;

    public mediaController: any;

    private playTimeout: any;

    private onClickHandler = (e) => this.onClick(e);

    private drawMatteHandler = (e) => this.handleDrawMatte(e);

    public cmFilter: ColorMatrixFilter;

    public adjustmentFilter: AdjustmentFilter;

    public overlayFilter: ColorOverlayFilter;

    private filters = [];

    constructor(props) {
        super(props);

        this.initFilters();
        this.contentSprite = new PIXI.Sprite();
        this.contentSprite.filters = this.filters;
        this.contentSprite.name = props.layerData.id;

        this.state = {
            status: LAYER_SOURCE_STATUSES.NO_SOURCE,
            playing: false,
            sourceHeight: null,
            sourceWidth: null,
            capturingScreenshot: false
        };
    }

    public componentWillUnmount(): void {
        this.clear();
        this.props.clearLayerSource();
        this.destroyFilters();
        document.removeEventListener(LAYER_DRAW_COMPLETE, this.drawMatteHandler);
        clearTimeout(this.playTimeout);
        void this.unmount();
    }

    public componentDidMount(): void {
        const {
            storyId,
            inView,
            mounted,
            variables,
            layerData: {
                id,
                start_frame,
                end_frame,
                type: layerType,
                options: { source }
            }
        } = this.props;

        const from = source ? source.from : null;
        const asset_tags = source ? source.asset_tags : null;

        if (!inView) {
            return;
        }

        if (mounted) {
            void this.mount();
        }

        if (from === SOURCE_TYPES.ASSET_TAGS && asset_tags && asset_tags.length > 0) {
            const duration = end_frame - start_frame;
            checkAvailableAssets(asset_tags, layerType, storyId, duration, id, variables);
        }

        document.addEventListener(LAYER_DRAW_COMPLETE, this.drawMatteHandler);

        this.initFilters();
        this.setZIndex();
    }

    public logInfo(message, subTier = false) {
        const name = trimLayerName(this.props.layerData?.name || '');
        if (!subTier) {
            console.debug(`Layer "${name}"  ----  ${message}`);
        } else {
            console.debug(`Layer "${name}"        ${message}`);
        }
    }

    public logError(message, e) {
        const name = trimLayerName(this.props.layerData?.name || '');
        console.error(`Layer "${name}" --- ${message}`);
        console.error(e);
    }

    public componentDidUpdate(prevProps, prevState) {
        const {
            mounted,
            layerData: { effects, locked, video_enabled, matte_layer_id },
            zIndex,
            isMatte,
            inView
        } = this.props;

        if (!inView) {
            return;
        }

        const checkVisibility = () => {
            if (!isMatte && video_enabled) {
                this.addContent();
            } else if (isMatte || !video_enabled) {
                this.removeContent();
            }
        };

        // If the component was mounted, and it should no longer be, unmount it
        if (prevProps.mounted && !mounted) {
            void this.unmount();

            // If the component was not mounted, and it should be, mount it
        } else if (!prevProps.mounted && mounted) {
            void this.mount();
        }

        if (prevProps.layerData?.matte_layer_id && !matte_layer_id) {
            this.clearMatte();
        }

        if (zIndex !== prevProps.zIndex) {
            this.setZIndex();
        }

        if (locked !== prevProps.layerData?.locked) {
            this.setInteractivity();
        }

        if (video_enabled !== prevProps.layerData?.video_enabled || isMatte !== prevProps.isMatte) {
            checkVisibility();
        }

        if (effects.volume !== prevProps.layerData.effects.volume) {
            this.volume();
        }
    }

    public checkForNewLayerSource(forceUseLocalAssets: boolean = false) {
        clearTimeout(this.layerSourceTimeout);
        this.layerSourceTimeout = setTimeout(() => {
            void this.doCheckForNewLayerSource(forceUseLocalAssets);
        }, 500);
    }

    public doCheckForNewLayerSource(forceUseLocalAssets: boolean = false) {
        return null;
    }

    public clearMatte() {
        if (this.matteSprite) {
            this.contentSprite.removeChild(this.matteSprite);
            this.matteSprite.destroy(true);
            this.matteSprite = null;
            this.contentSprite.mask = null;
        }

        if (this.matteTexture) {
            this.matteTexture.destroy(true);
            this.matteTexture = null;
        }
    }

    private handleDrawMatte(e) {
        const {
            compWidth,
            compHeight,
            layerData: { matte_layer_id }
        } = this.props;

        if (matte_layer_id && e.detail.layerId === matte_layer_id) {
            this.clearMatte();

            if (!e.detail.mounted) {
                this.matteSprite = new PIXI.Sprite();
            } else {
                this.matteTexture = this.props.pixiApp.renderer.generateTexture(e.detail.sprite, {
                    region: new PIXI.Rectangle(0, 0, compWidth, compHeight)
                });
                this.matteSprite = PIXI.Sprite.from(this.matteTexture);
            }

            this.props.parent.addChild(this.matteSprite);
            this.contentSprite.mask = this.matteSprite;
        }
    }

    public draw(seek = false) {
        const start = performance.now();
        this.resize();
        this.applyEffects();
        this.applyAnimations();
        if (seek) {
            this.seek();
        }
        const end = performance.now();
        const time = end - start;
        this.logInfo(`Draw complete in ${time.toFixed(2)}ms`, true);

        this.dispatchDrawCompleteEvent();

        return null;
    }

    public dispatchDrawCompleteEvent() {
        const evtData = {
            layerId: this.props.layerData.id,
            sprite: this.contentSprite,
            mounted: this.props.mounted
        };
        const event = new CustomEvent(LAYER_DRAW_COMPLETE, {
            detail: evtData
        });
        document.dispatchEvent(event);
    }

    public play() {
        if (this.props.mounted) {
            clearTimeout(this.playTimeout);

            // Offset the playing of the video by the frame seek offset
            const playDelay = (1 / this.props.frameRate) * FRAME_SEEK_OFFSET * 1000;
            // const playDelay = 5000;
            this.playTimeout = setTimeout(() => {
                if (this.mediaController) {
                    this.mediaController.play();
                }
            }, playDelay);
        }
    }

    public pause() {
        clearTimeout(this.playTimeout);
        if (this.mediaController) {
            this.mediaController.pause();
            if (!this.props.playing) {
                return this.seek();
            }
        }
    }

    public muted() {
        const { muted } = this.props;
        if (this.mediaController) {
            this.mediaController.internalMedia.muted = muted;
        }
    }

    public volume(keyframeVolume = null) {
        const {
            volume,
            layerData: { effects }
        } = this.props;
        if (this.mediaController.gainNode) {
            let multiplier = 1;
            if (keyframeVolume !== null) {
                multiplier = keyframeVolume;
            } else if (effects.hasOwnProperty('volume')) {
                multiplier = parseFloat(effects.volume) || 0;
            }

            this.mediaController.gainNode.gain.value = volume * multiplier;
        }
    }

    public seek() {
        // Update the layer matte, if there is one
    }

    public resize() {
        return null;
    }

    public onClick(e) {
        this.props.onClick(this.props.layerData.id, e.shiftKey);
    }

    public setZIndex() {
        this.contentSprite.zIndex = this.props.zIndex;
    }

    // Apply the animations for that frame, offsetFrame = the how many frames "into" the layer we are
    public applyAnimations() {
        const { keyframes } = this.props.layerData;
        this.logInfo(`Apply Layer Animations`, true);
        if (keyframes && keyframes.opacity && keyframes.opacity.length > 0) {
            const opacity = getInterpolatedConfigValue(keyframes.opacity, this.props.relativeFrame);
            this.adjustmentFilter.alpha = opacity;
        }

        if (keyframes && keyframes.volume && keyframes.volume.length > 0) {
            this.volume(getInterpolatedConfigValue(keyframes.volume, this.props.relativeFrame));
        }
    }

    private initFilters() {
        this.overlayFilter = new ColorOverlayFilter();
        this.overlayFilter.enabled = false;
        this.filters.push(this.overlayFilter);
        this.adjustmentFilter = new AdjustmentFilter();
        this.filters.push(this.adjustmentFilter);
        this.cmFilter = new ColorMatrixFilter();
        this.filters.push(this.cmFilter);
    }

    private destroyFilters() {
        this.contentSprite.filters = [];
        this.overlayFilter.destroy();
        this.cmFilter.destroy();
        this.adjustmentFilter.destroy();
        this.filters = [];
    }

    // PIXI effect pipeline
    public applyEffects() {
        const {
            renderMode,
            layerData: { effects }
        } = this.props;
        if (renderMode) {
            return;
        }
        const { opacity, saturation, brightness, blend_mode, hue } = effects;

        if (this.mediaSprite) {
            this.logInfo(`Apply Layer Effects`, true);
            // SATURATION
            if (saturation !== undefined) {
                this.adjustmentFilter.saturation = saturation;
            } else {
                this.adjustmentFilter.saturation = 1;
            }

            // OPACITY
            if (opacity !== undefined) {
                this.adjustmentFilter.alpha = opacity;
            } else {
                this.adjustmentFilter.alpha = 1;
            }

            // BRIGHTNESS - currently using a color overlay
            if (brightness !== undefined && brightness !== 0) {
                if (brightness < 0) {
                    const alpha = Math.abs(brightness) / 10;
                    this.overlayFilter.color = 0x000000;
                    this.overlayFilter.alpha = alpha;
                } else if (brightness > 0) {
                    const alpha = Math.abs(brightness) / 10;
                    this.overlayFilter.color = 0xffffff;
                    this.overlayFilter.alpha = alpha;
                }
                this.overlayFilter.enabled = true;
            } else {
                // If the overlay filter is in the filters array, remove it
                this.overlayFilter.enabled = false;
            }

            // //HUE
            if (hue !== undefined) {
                this.cmFilter.hue(hue, false);
            } else {
                this.cmFilter.hue(0, false);
            }

            if (blend_mode) {
                const mode = mapImposiumBlendModeToPixi(blend_mode);
                this.cmFilter.blendMode = mode || PIXI.BLEND_MODES.NORMAL;
            } else {
                this.cmFilter.blendMode = PIXI.BLEND_MODES.NORMAL;
            }
        }
    }

    public clear(): void {
        this.logInfo(`Clear Layer`);
        if (this.mediaSprite) {
            this.contentSprite.removeChild(this.mediaSprite);
            this.mediaSprite.destroy();
            this.mediaSprite = null;
        }

        if (this.mediaController) {
            this.mediaController = null;
        }

        this.clearMask();
    }

    public clearMask() {
        if (this.mediaSprite) {
            this.mediaSprite.mask = null;
        }
        if (this.maskGraphic) {
            this.contentSprite.removeChild(this.maskGraphic);
            this.maskGraphic.destroy();
            this.maskGraphic = null;
        }
    }

    public mount() {
        return new Promise<void>((resolve, reject) => {
            if (!this.props.isMatte && this.props.layerData.video_enabled) {
                this.addContent();
            }
            if (this.props.playing) {
                this.play();
            }
            resolve();
        });
    }

    public removeContent() {
        this.props.parent.removeChild(this.contentSprite);
    }

    public addContent() {
        this.props.parent.addChild(this.contentSprite);
    }

    public unmount() {
        return new Promise<void>((resolve, reject) => {
            if (this.props.parent) {
                this.removeContent();
            }
            if (this.mediaController) {
                this.mediaController.pause();
                this.mediaController.seek(0);
            }
            if (this.props.playing) {
                this.pause();
            }
            resolve();
        });
    }

    public createPlaceholder(interimPosition = null): void {
        const {
            layerData: {
                position_inputs,
                keyframes,
                type,
                options: { source }
            },
            relativeFrame,
            compWidth,
            compHeight
        } = this.props;

        if (type === COMPOSITION_LAYER_TYPES.AUDIO) {
            return;
        }

        const { status } = this.state;

        this.logInfo(`Draw Placeholder Sprite for status: ${status}`);

        const pos = interimPosition
            ? interimPosition
            : getLayerPosition(position_inputs, keyframes, relativeFrame, compWidth, compHeight);
        const { x, y, width, height } = pos;

        let bgColor = COMPOSITION_LAYER_COLORS[type];
        let bgAlpha = 0.3;

        if (type === COMPOSITION_LAYER_TYPES.SOLID && source.color) {
            bgColor = source.color;
            bgAlpha = null;
        }

        const copyAlpha = 1;
        const fontSize = 50;
        const placeholderCopy = getPlaceholderCopy(source, status);

        this.mediaSprite = layerSourceError({
            bgColor,
            copy: placeholderCopy.toUpperCase(),
            copyColor: COMPOSITION_LAYER_COLORS[type],
            copyAlpha,
            x,
            y,
            width,
            height,
            bgAlpha,
            fontSize
        });

        this.contentSprite.addChild(this.mediaSprite);
        void this.draw(false);
        this.setInteractivity();
    }

    public makeInteractive() {
        if (this.mediaSprite) {
            this.mediaSprite.eventMode = 'dynamic';
            this.mediaSprite.on('click', this.onClickHandler);
        }
    }

    private disableInteracrive() {
        if (this.mediaSprite) {
            this.mediaSprite.eventMode = 'none';
            this.mediaSprite.off('click', this.onClickHandler);
        }
    }

    public setInteractivity() {
        if (this.props.layerData.locked) {
            this.disableInteracrive();
        } else {
            this.makeInteractive();
        }
    }

    public createMask(x, y, w, h) {
        this.clearMask();

        this.maskGraphic = new PIXI.Graphics();
        this.maskGraphic.beginFill(0xffffff);
        this.maskGraphic.drawRect(0, 0, w, h);
        this.maskGraphic.x = x + -w / 2;
        this.maskGraphic.y = y + -h / 2;

        this.contentSprite.addChild(this.maskGraphic);
        this.mediaSprite.mask = this.maskGraphic;
    }

    public render() {
        return null;
    }
}

export const getPlaceholderCopy = (source, status): string => {
    if (status === LAYER_SOURCE_STATUSES.NO_SOURCE) {
        if (source.from === SOURCE_TYPES.INVENTORY) {
            if (source.inventory_id) {
                return copy.noVariablePreview;
            } else {
                return copy.noVariable;
            }
        } else if (source.from === SOURCE_TYPES.ASSET) {
            if (!source.asset_id) {
                return copy.noAssetSel;
            }
        } else if (source.from === SOURCE_TYPES.ASSET_TAGS) {
            if (source?.asset_tags.length === 0) {
                return copy.noTagsSet;
            } else {
                return copy.noAssetFound;
            }
        }
    } else if (status === LAYER_SOURCE_STATUSES.ERROR) {
        return copy.errorLoadingSource;
    } else if (status === LAYER_SOURCE_STATUSES.LOADING) {
        return copy.loadingSource;
    } else if (status === LAYER_SOURCE_STATUSES.NO_PREVIEW) {
        return copy.previewNotAvailable;
    } else if (status === LAYER_SOURCE_STATUSES.CIRCULAR_COMP) {
        return copy.recursiveComp;
    }

    return '';
};

const trimLayerName = (name) => {
    return name.substring(0, 8).padEnd(8);
};
export const mapImposiumBlendModeToPixi = (blend_mode: string): number => {
    const blendMapping = {
        AdditionBlend: PIXI.BLEND_MODES.ADD,
        DarkenBlend: PIXI.BLEND_MODES.DARKEN,
        DifferenceBlend: PIXI.BLEND_MODES.DIFFERENCE,
        ExclusionBlend: PIXI.BLEND_MODES.EXCLUSION,
        LightenBlend: PIXI.BLEND_MODES.LIGHTEN,
        MultiplyBlend: PIXI.BLEND_MODES.MULTIPLY,
        OverlayBlend: PIXI.BLEND_MODES.OVERLAY,
        ScreenBlend: PIXI.BLEND_MODES.SCREEN,
        SubtractBlend: PIXI.BLEND_MODES.SUBTRACT,
        XorBlend: PIXI.BLEND_MODES.XOR
    };

    return blendMapping[blend_mode] || PIXI.BLEND_MODES.NORMAL;
};
