import { ASSET_TYPES, doAssetTableHydration } from '@imposium-hub/components';
import { api } from '../constants/app';
import { IComposition } from '../constants/snippets';
import { getCompFromLayers, getLayerFromAsset } from './story';
import { circularCompCheck } from './timeline';
import store from '../redux/store';
import {
    deleteLayers,
    deleteLayer,
    addLayer,
    shiftLayers,
    createComposition
} from '../redux/actions/compositions';
import { updateTimelineState } from '../redux/actions/timeline';

export const getDupArrayAndIndexs = () => {
    const {
        compositions: { present: compositions },
        project: { compositionId },
        timeline: { activeMultiSelectLayers }
    } = store.getState();

    const activeComp = { ...compositions[compositionId] };
    const compLayers = activeComp.layers;
    const indexs = [];
    const layersArray = [];

    activeMultiSelectLayers.forEach((s) => {
        const layerIndex = compLayers.findIndex((i) => i.id === s);
        indexs.push(layerIndex);
        layersArray[layerIndex] = compLayers[layerIndex];
    });

    const dupArray = layersArray.filter((n) => n).reverse();

    return { dupArray, indexs };
};

export const getDupLayerIndex = () => {
    const {
        compositions: { present: compositions },
        project: { compositionId },
        timeline: { activeTimelineLayer }
    } = store.getState();

    const activeComp = { ...compositions[compositionId] };
    const compLayers = activeComp.layers;
    const index = compLayers.findIndex((layer) => layer.id === activeTimelineLayer);

    return index;
};

export const createCompFromLayers = (compName) => {
    const {
        story,
        compositions: { present: compositions },
        project: { compositionId },
        timeline: { activeTimelineLayer, activeMultiSelectLayers }
    } = store.getState();

    if (compName !== '') {
        const activeComp = { ...compositions[compositionId] };
        const compLayers = activeComp.layers;
        const compLayersIds = {};

        for (const compLayer of compLayers) compLayersIds[compLayer.id] = compLayer;

        let index = null;
        let selectedLayers;

        if (activeMultiSelectLayers.length > 0) {
            const { dupArray, indexs } = getDupArrayAndIndexs();
            index = Math.min(...indexs);
            selectedLayers = dupArray;
        } else {
            index = getDupLayerIndex();
            selectedLayers = [compLayersIds[activeTimelineLayer]];
        }

        const minStartFrame = Math.min(...selectedLayers.map((layers) => layers.start_frame));
        const maxEndFrame = Math.max(...selectedLayers.map((layers) => layers.end_frame));
        const totalFrame = maxEndFrame - minStartFrame;

        activeComp.frames = totalFrame;
        activeComp.background_color = 'transparent';

        const modifiedSelected = selectedLayers.map((layer) => {
            return {
                ...layer,
                start_frame: layer.start_frame - minStartFrame,
                end_frame: layer.end_frame - minStartFrame
            };
        });

        const comp = getCompFromLayers(modifiedSelected.reverse(), activeComp, compName);

        store.dispatch(createComposition(story.id, comp, comp.name)).then((c: IComposition) => {
            store.dispatch(doAssetTableHydration(api, story.id));

            api.getAssets({ type: ASSET_TYPES.VIDEO_COMPOSITION }, story.id).then((res) => {
                if (activeMultiSelectLayers.length > 0) {
                    store.dispatch(deleteLayers(compositionId, activeMultiSelectLayers));
                } else {
                    store.dispatch(deleteLayer(compositionId, activeTimelineLayer));
                }

                const selectedLayersComp = res.assets.filter(
                    (asset) => asset.name === comp.name
                )[0];

                if (circularCompCheck(selectedLayersComp)) return null;

                const baseVideo: any = activeComp?.videoFile;
                const width = baseVideo ? baseVideo.width : activeComp ? activeComp.width : null;
                const height = baseVideo ? baseVideo.height : activeComp ? activeComp.height : null;
                const totalFrames = baseVideo
                    ? baseVideo.totalFrames
                    : activeComp
                    ? activeComp.frames
                    : null;

                const layer = getLayerFromAsset(selectedLayersComp, width, height, totalFrames);

                store.dispatch(addLayer(compositionId, layer, index));
                store.dispatch(shiftLayers(compositionId, [layer.id], minStartFrame));
                store.dispatch(updateTimelineState({ activeTimelineLayer: layer.id }));
            });
        });
    }
};
